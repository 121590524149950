<template>
  <BoxSectionItem v-if="validOrganizations?.length" :title="titleMapped">
    <div class="flex gap-10 flex-wrap">
      <NuxtLink
        v-for="organization in validOrganizations"
        :key="organization.name"
        :to="organization.url"
        class="button is-action"
      >
        <SpriteSymbol name="baselstab" aria-hidden="true" />
        {{ organization.name }}
      </NuxtLink>
    </div>
  </BoxSectionItem>
</template>

<script setup lang="ts">
import type { TaxonomyTermOrganizationUnitButtonFragment } from '#graphql-operations'

const props = withDefaults(
  defineProps<{
    organizations?: TaxonomyTermOrganizationUnitButtonFragment[]
    title?: 'responsible' | 'department'
  }>(),
  {
    organizations: () => [],
    title: 'responsible',
  },
)

const { $texts } = useEasyTexts()

const validOrganizations = computed(() =>
  props.organizations?.filter((v) => v.url),
)

const titleMapped = computed<string>(() => {
  if (props.title === 'responsible') {
    return $texts(
      'topicPage.responsibleOrganisations',
      'Für dieses Thema zuständig',
    )
  } else if (props.title === 'department') {
    return $texts('events.department', 'Dienststelle')
  }

  return ''
})
</script>
